<template>
  <page-header-wrapper style="margin: unset;">
    <a-row :gutter='24'>
      <a-col :sm='12' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">实时现金折扣：￥{{dashboard.storeCash + dashboard.centerCash + dashboard.presidentCash}}</div>
          <div class="card_title" style="line-height: 30px;">1：{{dashboard.presidentCash}}</div>
          <div class="card_title" style="line-height: 30px;">2：{{dashboard.centerCash}}</div>
          <div class="card_title" style="line-height: 30px;">3：{{dashboard.storeCash}}</div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本周获得：￥{{dashboard.lastWeekCash1 + dashboard.lastWeekCash2 + dashboard.lastWeekCash3}}</div>
          <div class="card_title" style="line-height: 30px;">1：{{dashboard.lastWeekCash1}}</div>
          <div class="card_title" style="line-height: 30px;">2：{{dashboard.lastWeekCash2}}</div>
          <div class="card_title" style="line-height: 30px;">3：{{dashboard.lastWeekCash3}}</div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">本周使用：￥{{dashboard.lastUsedWeekCash1 + dashboard.lastUsedWeekCash2 + dashboard.lastUsedWeekCash3}}</div>
          <div class="card_title" style="line-height: 30px;">1：{{dashboard.lastUsedWeekCash1}}</div>
          <div class="card_title" style="line-height: 30px;">2：{{dashboard.lastUsedWeekCash2}}</div>
          <div class="card_title" style="line-height: 30px;">3：{{dashboard.lastUsedWeekCash3}}</div>
        </a-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='memberType'>
        <a-select v-model="form.memberType" placeholder="用户类型" style="width: 250px">
          <a-select-option :value="undefined">
            用户类型
          </a-select-option>
          <a-select-option value="president">
            1
          </a-select-option>
          <a-select-option value="center">
            2
          </a-select-option>
          <a-select-option value="store">
            3
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 250px">
          <a-select-option value="-1">
            全部
          </a-select-option>
          <a-select-option v-for="i in 7" :key="i" :value="i">
            {{i}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="姓名/手机号" />
      </a-form-model-item>

      <a-form-model-item prop='type'>
        <a-select v-model="form.type" placeholder="变动类型" style="width: 250px">
          <a-select-option :value="undefined">
            变动类型
          </a-select-option>
<!--          <a-select-option :value="1">-->
<!--            使用-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="2">-->
<!--            修改-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="3">-->
<!--            奖励-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="5">-->
<!--            转让-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="6">-->
<!--            受让-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="7">-->
<!--            取删退回-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="8">-->
<!--            活动-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="9">-->
<!--            现金补贴-->
<!--          </a-select-option>-->
          <a-select-option :value="1">
            使用
          </a-select-option>
          <a-select-option :value="2">
            修改
          </a-select-option>
          <a-select-option :value="5">
            转让
          </a-select-option>
          <a-select-option :value="7">
            取删退回
          </a-select-option>
          <a-select-option :value="19">
            消费积分
          </a-select-option>
          <a-select-option :value="21">
            批兑商品
          </a-select-option>
<!--          <a-select-option :value="22">-->
<!--            批兑驳回-->
<!--          </a-select-option>-->
          <a-select-option :value="23">
            解约
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item> -->
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <template>
          <span style="margin-left: 10px;margin-right: 10px;"> 笔数：{{this.total}}</span>
          <span> 变动值：{{this.ext4}}</span>
        </template>
        <template v-if="ext.ext1 !== ''">
          <span>获得：{{ext.ext1 || 0}}</span>
          <span style="padding-left: 20px;">使用：{{ext.ext2 || 0}}</span> 
          <span style="padding-left: 20px;">待用：{{ext.ext3 || 0}}</span>
        </template>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='memberType' slot-scope='text'>
          <template>
            {{ text == "store" ? "3" : text == "center" ? "2" : text == "president" ? "1" :  "" }}
          </template>
        </span>
        <span slot='businessContent' slot-scope='text, record'>
          <template>
            {{ (record.afterBalance - record.beforeBalance) | parseMoney }}
          </template>
        </span>
        <span slot='orderId' slot-scope='text, record'>
          <template>
             {{showText(record)}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { getAuditOrderData, queryCash } from '@/api/member-order'
import moment from 'moment'
import { getUserTypeName } from '@/utils/util'


const columns = [
  {
    width: 100,
    title: '用户类型',
    dataIndex: 'memberType',
    scopedSlots: { customRender: 'memberType' }
  },
  {
    width: 100,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 120,
    title: '变动值',
    dataIndex: 'businessContent',
    scopedSlots: { customRender: 'businessContent' }
  },
  {
    width: 100,
    title: '变动后数值',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 200,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 200,
    title: '时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 180,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' }
  },
  {
    width: 150,
    title: '姓名',
    dataIndex: 'userName',
    scopedSlots: { customRender: 'userName' }
  },
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'AuditCashList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        // start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        // end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      ext: {
        ext1: '',
        ext2: '',
        ext3: '',
      },
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      total:0,
      ext4:0,
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryCash(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            debugger;
            this.total = datum.total;
            this.ext4 = datum.ext4;
            this.ext = {
              ext1: datum.ext1 != undefined ? datum.ext1 : '',
              ext2: datum.ext2,
              ext3: datum.ext3,
            }
            return datum
          })
      },
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    showText(record){
      // debugger;
      let text = "";
      if(record.recordType===2|| record.recordType === 9){
        text = record.opUsername;
      }else if(record.recordType === 6){
        text = record.transferFromUserName+"转";
      }else if(record.recordType === 5){
        text = record.transferToUserName+"得";
      }else{
        text = record.orderId;
      }
      return text;
    },
    moment,
    getUserTypeName,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      // this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      // this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      if(this.form.endTag === "-1"){
        delete this.form.endTag;
      }
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      getAuditOrderData(Object.assign({}, this.form)).then(result => {
        result.wealthQuotaAmount = result.wealthQuotaAmount || 0,
        result.wealthZxQuotaAmount = result.wealthZxQuotaAmount || 0,
        result.wealthBtcfQuotaAmount = result.wealthBtcfQuotaAmount || 0,
        result.totalQuotaAmount = result.wealthQuotaAmount + result.wealthZxQuotaAmount + result.wealthBtcfQuotaAmount;
        result.totalQuotaAmount = parseFloat(result.totalQuotaAmount.toFixed(2));
        result.storeCash = result.storeCash || 0,
        result.centerCash = result.centerCash || 0,
        result.presidentCash = result.presidentCash || 0,
        result.totalCash = result.storeCash + result.centerCash + result.presidentCash;
        result.totalCash = parseFloat(result.totalCash.toFixed(2));
// debugger;
        result.lastWeekCash1 = result.lastWeekCash1||0;
        result.lastWeekCash2 = result.lastWeekCash2||0;
        result.lastWeekCash3 = result.lastWeekCash3||0;

        result.lastUsedWeekCash1 = result.lastUsedWeekCash1||0;
        result.lastUsedWeekCash2 = result.lastUsedWeekCash2||0;
        result.lastUsedWeekCash3 = result.lastUsedWeekCash3||0;

        this.dashboard = result;
        this.loading = false;
      })
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
</style>
